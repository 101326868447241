import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  // Level,
  // Tile,
  Columns,
  Content
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import UnderstandingIcon from '../../content/assets/icons/about-us/deep-understanding.svg'
import LampIcon from '../../content/assets/icons/about-us/lamp.svg'
import ResultsIcon from '../../content/assets/icons/about-us/results.svg'
import ChallengesIcon from '../../content/assets/icons/about-us/challenges.svg'
import FlexibilityIcon from '../../content/assets/icons/about-us/flexibility.svg'
import KnowledgeIcon from '../../content/assets/icons/about-us/knowledge-sharing.svg'

import { Tablet, Mobile } from '../utils/presets'

// eslint-disable-next-line
const IconBox = ({ children, ...props }) =>
  (
  <div
    css={{
      display: 'flex',
      backgroundColor: '#F1F2FD',
      borderRadius: 6,
      padding: '1rem',
      margin: '.5rem',
      width: 65
    }}
    {...props}
  >
    {children}
  </div>
)

// eslint-disable-next-line
const DescriptionBlock = ({ title, description, ...props }) =>
(
  <div
    css={{
      display: 'flex'
    }}
    {...props}
  >
    <p css={{ color: '#5B59FB', fontWeight: 'bold' }}>{title}</p>
  </div>
)

class AboutPage extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="About us" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 200 }}>
              <Container className="has-text-centered">
                <Section>
                  <Heading size={1} renderAs="h1">
                    About us
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <Container className="has-text-centered">
              <Section>
                <Box>
                  <Section>
                    <Heading size={1} renderAs="h2">
                      Our story
                    </Heading>
                    <Content>
                      We are educated and passionate professionals and started
                      our careers around 2010. We both worked at numerous
                      Ukrainian and foreign IT companies: outsourcing, startups,
                      products and large corporations. Along the way, we solved
                      unique business issues that were present to the companies
                      we worked for. The more experience we gained, the more
                      refined our approaches and the solutions were becoming,
                      the more we thought about how things could be done in a
                      different way. Being on the edge of the market we came up
                      with the idea to create our own company with our own way
                      of doing things, the way we see the most efficient.
                      <br />
                      That is how and why Tangibly was founded - to understand
                      the needs of the business and create tangible solutions.
                    </Content>
                  </Section>
                  <Columns>
                    <Columns.Column offset={3} size={3}>
                      <Box className="is-paddingless">
                        <Image
                          alt="Dmytro"
                          fluid={this.getImage('dmytroImage')}
                          style={{
                            width: '100%',
                            relative: 'relative',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px'
                          }}
                        />
                        <div css={{ padding: '1.25rem', textAlign: 'left' }}>
                          <Heading
                            size={5}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Dmytro
                          </Heading>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '.5rem .5rem 0' }}
                          >
                            Cofounder
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h5"
                            style={{
                              marginTop: '1rem',
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                          >
                            Product marketing manager and passionate
                            enterpreneur
                          </Heading>
                        </div>
                      </Box>
                    </Columns.Column>
                    <Columns.Column size={3}>
                      <Box className="is-paddingless">
                        <Image
                          alt="Taras"
                          fluid={this.getImage('tarasImage')}
                          style={{
                            width: '100%',
                            relative: 'relative',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px'
                          }}
                        />
                        <div css={{ padding: '1.25rem', textAlign: 'left' }}>
                          <Heading
                            size={5}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Taras
                          </Heading>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '.5rem .5rem 0' }}
                          >
                            Cofounder
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h5"
                            style={{
                              marginTop: '1rem',
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                          >
                            Software Engineer with more than 10 years of
                            experience
                          </Heading>
                        </div>
                      </Box>
                    </Columns.Column>
                  </Columns>
                  <Section>
                    <Heading size={1} renderAs="h1">
                      Our mission
                    </Heading>
                    <Content>
                      We turn the most uncertain business and tech cases into
                      tangible state of an art solutions.
                    </Content>
                    <Columns>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <UnderstandingIcon />
                          </IconBox>
                          <DescriptionBlock title="DEEP UNDERSTANDING" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <LampIcon />
                          </IconBox>
                          <DescriptionBlock title="RATIONAL CREATIVITY" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ResultsIcon />
                          </IconBox>
                          <DescriptionBlock title="TANGIBLE RESULTS " />
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ChallengesIcon />
                          </IconBox>
                          <DescriptionBlock title="PASSION TO CHALLENGES" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <FlexibilityIcon />
                          </IconBox>
                          <DescriptionBlock title="FLEXIBILITY" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={4}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <KnowledgeIcon />
                          </IconBox>
                          <DescriptionBlock title="KNOWLEDGE SHARING" />
                        </div>
                      </Columns.Column>
                    </Columns>
                  </Section>
                </Box>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

AboutPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    dmytroImage: file(absolutePath: { regex: "/dmytro@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tarasImage: file(absolutePath: { regex: "/taras@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
